import type { RouteLocationRaw } from 'vue-router'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'

export const createDashboardReportURI = (
  companyId: string,
  link: ISearchPropertyLeasePortfolioRequest | undefined,
  templateId?: string,
): RouteLocationRaw | undefined => {
  if (!link) return
  return {
    path: `/division/${companyId}/reports/view`,
    query: {
      template: templateId ?? '2f8697e6-6518-4fcc-afb7-c89794c18443',
      // @ts-expect-error RouteLocationRaw don't expect object
      filters: link,
    },
  }
}

export const createDashboardPortfolioURI = (
  companyId: string,
  link: ISearchPropertyLeasePortfolioRequest | undefined,
): RouteLocationRaw | undefined => {
  if (!link) return
  return {
    path: `/division/${companyId}/portfolio`,
    query: {
      // @ts-expect-error RouteLocationRaw don't expect object
      filters: link,
    },
  }
}
